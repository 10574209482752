import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { ConnMessageService } from 'src/app/_services/conn-message.service';
import { SignupService } from 'src/app/_services/signup.service';

export interface OnlineStoreOptions{
    id: number;
    title: string;
    description: string;
    selected: boolean;
    allowed: boolean;
}

export let OnlineStoreOptionsList: OnlineStoreOptions[] =[
    {
        id: 1,
        title: "Yes, I would love to have one.",
        description: "I want to sell online too.",
        selected: false,
        allowed: true
    },
    {
        id: 2,
        title: "No, I don’t want to have one.",
        description: "I will sell through my physical store only.",
        selected: false,
        allowed: false
    },
]
@Component({
  selector: 'app-signup-website-and-online-store',
  templateUrl: './signup-website-and-online-store.component.html',
  styleUrls: ['./signup-website-and-online-store.component.scss']
})
export class SignupWebsiteAndOnlineStoreComponent implements OnInit {
    showDomainDialog = false

    value: any

    @Input() showFooter: boolean = true
    @Output() calcEcommercePrice = new EventEmitter<any>()
    @Output() closeDialogEcomm = new EventEmitter<any>()

    @Input() checkoutPage = new EventEmitter<boolean>()
    onlineStoreOptionsList = OnlineStoreOptionsList;
    onlineECommerceWebsiteSelected: boolean = false;
    isCustomDomainDirty: boolean = false;
    disableNextButton: boolean = true;
    @Output() backButtonClick = new EventEmitter();
    anyPlanFreeFound : boolean = false;
    showBackButton : boolean = true;
    constructor(
        public _abd: AppBaseDataService,
        public _signup: SignupService,
        private _connMessageService: ConnMessageService,
        private _spinner: NgxSpinnerService,
    ) { }

    ngOnInit(): void {
        if (this._signup.eComSubscriptionPlans == undefined) {
            this._signup.recalEcomPlans()
        }

        this.initStepFourSavedForm();




    }

    initWebsiteAndOnlineStore(){

        try {

            if (this.onlineStoreOptionsList.filter(f => f.id == 1)[0].selected) {

              //  this.onlineECommerceWebsiteSelected = this._signup.eComSubscriptionPlans != null ? true : false;
               // let onlineECommerceWebsiteSelected: any = sessionStorage.getItem('onlineECommerceWebsiteSelected');
              //  if (onlineECommerceWebsiteSelected != undefined) {
               //     this.onlineECommerceWebsiteSelected = onlineECommerceWebsiteSelected == "1" ? true : false;
             //   }
                this.disableNextButton = false;
                this.updatePlanInfo();

            }



        } catch (error) {
            console.log(error.message, new Date())
        }
    }

    updatePlanInfo(){
        let self = this;
        this._signup.eComSubscriptionPlans.map(function (value) {

            if (value.pricePlan.filter(f => f.planID == self._signup.selectedPlan.planId).length > 0) {

                if (self._signup.selectedPlanTimeframe == 'BilledMonthly') {
                    value.price = value.pricePlan.filter(f => f.planID == self._signup.selectedPlan.planId)[0].monthlyPrice;
                }
                if (self._signup.selectedPlanTimeframe != 'BilledMonthly') {
                    value.price = value.pricePlan.filter(f => f.planID == self._signup.selectedPlan.planId)[0].monthlyPrice;
                }

                value.includedInPackage = false;
                if (value.price == 0 ) {
                    value.includedInPackage = true;
                }
            }
        })
        this.anyPlanFreeFound = this._signup.eComSubscriptionPlans.filter(f => f.includedInPackage).length > 0 ? true: false;
    }

    initStepFourSavedForm() {
        try {

            let subscriptionAgain: any = sessionStorage.getItem('sub-from-dashboard');

            if (subscriptionAgain != undefined) {
                this.showBackButton = false;
            }
        let self = this;
       // let onlineECommerceWebsiteSelected: any = sessionStorage.getItem('onlineECommerceWebsiteSelected');
        let selectedWebsiteAndOnlineStorePlan: any = sessionStorage.getItem('selectedWebsiteAndOnlineStorePlan');
        let showCustomDomain: any = sessionStorage.getItem('showCustomDomain');
       // if (onlineECommerceWebsiteSelected != undefined) {

         //   this.onlineECommerceWebsiteSelected = onlineECommerceWebsiteSelected == "1" ? true: false;
         //   if (this.onlineECommerceWebsiteSelected){
             //   this.onlineStoreOptionsList.map(f => f.selected = false)
             //   this.onlineStoreOptionsList[0].selected = true;
                if (selectedWebsiteAndOnlineStorePlan != undefined && selectedWebsiteAndOnlineStorePlan != null
                    && this._signup.eComSubscriptionPlans != undefined && this._signup.eComSubscriptionPlans != null){
                    selectedWebsiteAndOnlineStorePlan = JSON.parse(selectedWebsiteAndOnlineStorePlan);
                    // console.log(selectedWebsiteAndOnlineStorePlan)
                    this._signup.eComSubscriptionPlans.filter(f => f.id == selectedWebsiteAndOnlineStorePlan.id )[0].selected = true
                    this._signup.selectedWebsiteAndOnlineStorePlan = selectedWebsiteAndOnlineStorePlan
                }

         //   }
         //   else{
            //    this.onlineStoreOptionsList.map(f => f.selected =false)
            //    this.onlineStoreOptionsList[1].selected = true;
        //    }

            //this._signup.eComSubscriptionPlans.filter(f => f.id == this._signup.selectedWebsiteAndOnlineStorePlan.id).map(f => f.selected = !f.selected)
            this.updatePlanInfo();
       // }
        } catch (error) {
            console.log(error.message, new Date())
        }

    }


    nextStep() {

       try {
            // if no plan selected
            // but have plan include default in subscription
            // if (this.onlineStoreOptionsList.filter(f => f.id == 2)[0].selected){
            //     if (this._signup.eComSubscriptionPlans.includedInPackage) {
            //         this.showDomainDialog = true;
            //     }
            // }
        //    if (this._signup.eComSubscriptionPlans && this.onlineStoreOptionsList.filter(f => f.id == 1)[0].selected) {
        //        this.showDomainDialog = true;
        //    }

           if (this.onlineStoreOptionsList.filter(f => f.id == 1)[0].selected && this._signup.selectedWebsiteAndOnlineStorePlan != null) {
               if (this._signup.selectedWebsiteAndOnlineStorePlan.selected){
                   this.showDomainDialog = true;
               }

               else{
                   this._signup.currentStep++;
                   this._signup.savedSteps++;
                   sessionStorage.setItem('currentStep', this._signup.currentStep.toString());
                   sessionStorage.setItem('savedSteps', this._signup.savedSteps.toString());
               }
            }
           else {
               if (this.checkoutPage) {
                   this._signup.currentStep = 8;
                  // sessionStorage.setItem('onlineECommerceWebsiteSelected', this.onlineStoreOptionsList[0].selected ? "1" : "0")
                   // any plan selected
                   if (this._signup.eComSubscriptionPlans.filter(f => f.selected).length > 0) {
                       sessionStorage.setItem('selectedWebsiteAndOnlineStorePlan', JSON.stringify(this._signup.selectedWebsiteAndOnlineStorePlan))
                   }


                   this.closeDialogEcomm.emit()
               }
               else {

                   this._signup.currentStep++;
                   this._signup.savedSteps++;
                   if (this._signup.savedSteps > 8){
                       this._signup.savedSteps = 8
                   }

                  // sessionStorage.setItem('onlineECommerceWebsiteSelected', this.onlineStoreOptionsList[0].selected ? "1" : "0")
                   if (this._signup.eComSubscriptionPlans.filter(f => f.selected).length > 0) {

                       sessionStorage.setItem('selectedWebsiteAndOnlineStorePlan', JSON.stringify(this._signup.selectedWebsiteAndOnlineStorePlan))
                   }
                   else{
                   //    sessionStorage.setItem('onlineECommerceWebsiteSelected', this.onlineStoreOptionsList[0].selected ? "1" : "0")
                       this._signup.selectedWebsiteAndOnlineStorePlan = null;
                       sessionStorage.removeItem('selectedWebsiteAndOnlineStorePlan')
                       sessionStorage.removeItem('showCustomDomain')
                   }

                //    if (!this.onlineStoreOptionsList[0].selected){
                //        this._signup.selectedWebsiteAndOnlineStorePlan = null;
                //        sessionStorage.removeItem('showCustomDomain')
                //    }


                   sessionStorage.setItem('currentStep', this._signup.currentStep.toString());
                   sessionStorage.setItem('savedSteps', this._signup.savedSteps.toString());
               }
           }
       } catch (error) {
            console.log(error.message)
       }

        // if (this.showFooter)
        // this._signup.currentStep++;
        // else
        // this.closeDialogEcomm.emit()
    }

    backStep() {
        if (this._signup.accountVerified) {
            this.backButtonClick.emit(true);
            this._signup.currentStep = 2
            this._signup.savedSteps = 2
        }
        else {
            this.backButtonClick.emit(true);
            this._signup.currentStep--;
            this._signup.savedSteps--; 
        }

        let subscriptionAgain: any = sessionStorage.getItem('sub-from-dashboard');
        if (subscriptionAgain != undefined) {
            if (this._signup.currentStep < 4) {
                this._signup.currentStep = this._signup.currentStep;
                this._signup.savedSteps = this._signup.savedSteps;
            }
        }
        sessionStorage.setItem('currentStep', this._signup.currentStep.toString());
        sessionStorage.setItem('savedSteps', this._signup.savedSteps.toString());
    }

    selectPlan( item:any) {

        if (!item.selected){


            this._signup.selectedWebsiteAndOnlineStorePlan = null
            //if (item.selected) this._signup.selectedWebsiteAndOnlineStorePlan = item
            this._signup.selectedWebsiteAndOnlineStorePlan = item
            if (!this._signup.selectedDomainPlan)
            this._signup.selectedDomainPlan = this._signup.selectedWebsiteAndOnlineStorePlan.domainPlan[0]

            if (this._signup.selectedDomainPlan.description.startsWith('Yes') && this._signup.selectedDomainPlan.monthlyPrice>0)
            this._signup.showCustomDomain = true
            else this._signup.showCustomDomain = false

            let obj = this._signup.eComSubscriptionPlans

            for (let i = 0; i < obj.length; i++) {
                item.id == obj[i].id && item.selected ? obj[i].selected = true : obj[i].selected = false
            }

            this._signup.eComSubscriptionPlans.filter(f=> f.id == item.id ).map(f=> f.selected = !f.selected)
            this._signup.eComSubscriptionPlans.filter(f => f.id == item.id)[0].selected = item.selected
            // console.log('this._signup.selectedWebsiteAndOnlineStorePlan', this._signup.selectedWebsiteAndOnlineStorePlan)
            if (!this.value)
            this.value = this._signup.selectedWebsiteAndOnlineStorePlan.domainPlan[0]
            //this.showDomainDialog = this.disableSelectButton(item);
            if (!this.isCustomDomainDirty){
                this._signup.selectedWebsiteAndOnlineStorePlan.domainPlan.map(f => f.selected = false)
            }

            this.disableNextButton = false;
            let showCustomDomain: any = sessionStorage.getItem('showCustomDomain');
            if (showCustomDomain != undefined) {
                this.isCustomDomainDirty = true;
                this.disableNextButton = false;
                if (showCustomDomain == "1"){
                    this._signup.selectedWebsiteAndOnlineStorePlan.domainPlan[0].selected = (parseInt(showCustomDomain) == 1 ? true : false);
                    this._signup.selectedWebsiteAndOnlineStorePlan.domainPlan[1].selected = false;
                }
                else{
                    this._signup.selectedWebsiteAndOnlineStorePlan.domainPlan[0].selected = (parseInt(showCustomDomain) == 1 ? true : false);
                    this._signup.selectedWebsiteAndOnlineStorePlan.domainPlan[1].selected = false;
                }
            }
            this.showDomainDialog = true;
          //  sessionStorage.setItem('onlineECommerceWebsiteSelected', this.onlineStoreOptionsList[0].selected  ? "1" : "0")
            sessionStorage.setItem('selectedWebsiteAndOnlineStorePlan', JSON.stringify(this._signup.selectedWebsiteAndOnlineStorePlan))

        }
        else{
            item.selected = !item.selected
            sessionStorage.removeItem('selectedWebsiteAndOnlineStorePlan')
            this._signup.selectedWebsiteAndOnlineStorePlan = null;
        }
        this.calcEcommercePrice.emit()
    }

    disableSelectButton(item) {
        let ret = false
        let obj = item.pricePlan

        for (let i = 0; i < obj.length; i++) {
            if (this._signup.selectedPlan.planId == obj[i].planID && obj[i].monthlyPrice > 0)
                return true
        }

        return ret
    }

    domainPlanClicked(e:any) {
        try {

            this._signup.selectedWebsiteAndOnlineStorePlan.domainPlan.map(f => f.selected = false)

            e.selected = !e.selected;
            // this._signup.selectedDomainPlan = e.value
            if (e.description.startsWith('Yes') && e.monthlyPrice > 0)
                this._signup.showCustomDomain = true
            else this._signup.showCustomDomain = false

            if (e.monthlyPrice > 0){
                if (this._signup.selectedPlanTimeframe == 'BilledMonthly') {
                    this._signup.checkoutPageCalcVars.customDomainCost = e.monthlyPrice
                }
                else{
                    this._signup.checkoutPageCalcVars.customDomainCost = e.monthlyPrice * 12
                }
            }
            else
                this._signup.checkoutPageCalcVars.customDomainCost = 0
            this.isCustomDomainDirty = true;
            sessionStorage.setItem('showCustomDomain', this._signup.showCustomDomain ? "1" : "0")
            this.calcEcommercePrice.emit()
        } catch (error) {

        }
    }

    selectWebsiteOption(o: OnlineStoreOptions){
        this.onlineStoreOptionsList.filter(f=> f.id != o.id).map(f=> f.selected = false)
        o.selected = !o.selected;

       // this.onlineECommerceWebsiteSelected = false;
        this.disableNextButton = false;
        if (this.onlineStoreOptionsList.filter(f => f.id == 1)[0].selected){
          //  this.onlineECommerceWebsiteSelected = true;
            this.disableNextButton = false;
            this.updatePlanInfo();
           // this._signup.eComSubscriptionPlans.map(f => f.selected = false)
        }
    }

    hideCustomDomainPopup(){

       // this._signup.selectedDomainPlan = this._signup.selectedWebsiteAndOnlineStorePlan.domainPlan[0]

      try {

          let selectedPlan = this._signup.selectedWebsiteAndOnlineStorePlan.domainPlan.filter(f => f.selected)[0]
          if (selectedPlan.description.startsWith('Yes') && selectedPlan.monthlyPrice > 0)
              this._signup.showCustomDomain = true
          else this._signup.showCustomDomain = false

          this.showDomainDialog = !this.showDomainDialog;
          if (this.checkoutPage) {

              this._signup.currentStep = 8;
              // default include in package
              // if (this._signup.eComSubscriptionPlans.filter(f => f.includedInPackage).length > 0) {
              //     sessionStorage.setItem('selectedWebsiteAndOnlineStorePlan', JSON.stringify(this._signup.selectedWebsiteAndOnlineStorePlan))
              // }
          }
          else {
              this._signup.currentStep++;
              this._signup.savedSteps++;
              // sessionStorage.setItem('onlineECommerceWebsiteSelected', this.onlineECommerceWebsiteSelected ? "1" : "0")
              // sessionStorage.setItem('onlineECommerceWebsiteSelected', this.onlineECommerceWebsiteSelected ? "1" : "0")
              sessionStorage.setItem('selectedWebsiteAndOnlineStorePlan', JSON.stringify(this._signup.selectedWebsiteAndOnlineStorePlan))

              // default include in package
              if (this._signup.eComSubscriptionPlans.filter(f => f.includedInPackage).length > 0) {
                  sessionStorage.setItem('selectedWebsiteAndOnlineStorePlan', JSON.stringify(this._signup.selectedWebsiteAndOnlineStorePlan))
              }

              sessionStorage.setItem('currentStep', this._signup.currentStep.toString());
              sessionStorage.setItem('savedSteps', this._signup.savedSteps.toString());
          }
          this.closeDialogEcomm.emit()
      } catch (error) {

      }

    }

}
