import { environment } from 'src/environments/environment';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Product } from '../../api/product';
import { ProductService } from '../../service/productservice';
import { Subscription } from 'rxjs';
import { ConfigService } from '../../service/app.config.service';
import { AppConfig } from '../../api/appconfig';
import { BaseService } from 'src/app/_services/base.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { formatDate } from '@angular/common';
import { AppBaseDataService } from 'src/app/_services/app-base-data.service';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { GlobalService } from 'src/app/_services/global.service';
import { LocationsService } from 'src/app/_services/locations.service';
import { ProductsService } from 'src/app/_services/products.service';
import { CustomersService } from 'src/app/_services/customers.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { WorldTimeZone } from 'src/app/_data/time-zones';
import { FormControl } from '@angular/forms';
import { BusinessSetupService } from 'src/app/_services/business-setup.service';
@Component({
    templateUrl: './dashboard.component.html',
    styles: [`
        .progressbar-width{
            width:83% !important;
        }
        .progressbar-persent{
            color: var(--primary-color);
        }
       .p-panel{
            border-radius: 20px !important;
        }
.slider-container {
  overflow: hidden;
}

.slider-content {
  display: flex;
  transition: transform 0.5s;
}

.slider-item {
  flex: 0 0 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.active-slider{
    background: var(--primary-color) !important;
    cursor:pointer;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    margin:0px 2px;
}
.inactive-slider{
    background:#d9d9d9 !important;
    cursor:pointer;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    margin:0px 2px;
}

.panel {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
}
.open {
  max-height: 200px;
}
.panel-content {
  display: none;
}

.panel-open {
  display: block;
} 
.trile-panel{
    position:sticky;
    top:0;
    bottom:0;
    height:fit-content;
    background:black !important;
    color:white !important;
}
.trile-panel-sticky{
    position:fixed;
    bottom:40px;
    right:40px;
    height:fit-content;
    background:black !important;
    color:white !important;
}
.select-plan{
    background: white;
    color: black;
}
.bg-color{
background:var(--gray-800);
}

 .expanding-div {
  overflow: hidden;
  transition: height 0.5s ease; /* Adjust the duration for your desired speed */
}
.p-progressbar .p-progressbar-value {
    border: 0 none;
    margin: 0;
    background: #f00 !important;
}
.clickable:hover{
    background-color: #dfdfdf !important;
    cursor: pointer;    
}
.heading{
 color:var(--brandColor);  
 font-size: large
}
.spin-color{
    color:var(--brandColor) !important; 
}


`,],
animations: [
    trigger('expandAnimation', [
      state('expanded', style({ height: '*' })), // Height increases to fit content
      state('collapsed', style({ height: '0' })), // Collapsed state
      transition('expanded <=> collapsed', animate('500ms ease-in-out')), // Adjust the duration as needed
    ]),]
})

export class DashboardComponent implements OnInit,OnDestroy  {
    _global = GlobalService
    selectedPeriod = "Today";
    dashboardFilter = "0";
    showMainFilterPanel: boolean
    saleWidgetmain: any;
    salesWidgetData: any;
    ordersWidgetData: any;
    dailysales: any;
    dailystackedOptions: any;
    products: Product[];
    chartData: any;
    chartOptions: any;
    subscription: Subscription;
    config: AppConfig;
    dataList: any = ''
    topSellingProducts: any = []
    lowStockProducts: any = []
    timeTrackingReport: any = []
    isTimeTrackingRefresh: boolean = false;
    activeOrder:any = 1
    videosTutorial:any[] =[]

    //==============charts
    sevenDaySalesData: any;
    dailyAvgSale: any;

    lowStockProductSelectedRow: any = null
    showLowStockProduct = false
    gProductIdLowStockProduct: any

    timeZoneList:any[] = WorldTimeZone
    selectedTimeZone:FormControl = new FormControl()   
    dateRange = []
    //====
    proressBar=6;
    isPanelOpen = false;
    isTopPanelOpen = false;
    expandState = 'collapsed'; 
    expandPanelState = 'expanded'; 
    active:boolean=false;
    //=====
    slides = ['Slide 1', 'Slide 2', 'Slide 3'];
    currentSlide = 0;
    slideOffset:any;

    // Current User Status
    _globals = GlobalService
    freeTrail:any;
    dayLeftInFreeTrial: Date;
    IsUserOnTrial=false
    // ====
    // ====
    // Setup Guide
    outOf:any = 0;
    isLoadingSetup:boolean  = false;
    isConfigration:any;
    isLocation:any;
    isInventory:any;
    isCustomer:any;
    // Lincsell---
    isShipping:any;
    isPaymentProcessing:any;
    // Lincsell---
    isPosAppDownload:any = {status:false, img:'../../../assets/images/circle.svg'};
    isInvoice:any;

    items: MenuItem[] = [
        { id: '1', label: 'Today', command: (event) => { this.dateMenuOption(event) } },
        { id: '2', label: 'Current Month', command: (event) => { this.dateMenuOption(event) } },
        { id: '5', label: 'Current Year', command: (event) => { this.dateMenuOption(event) } },
        // { id: '5', label: 'Calander', command: (event) => { this.dateMenuOption(event) } },
    ]
    InVentoryActionitems: MenuItem[] = [
        { id: '1', label: 'Import Inventory', command: (event) => { this.handleInventoryAction(event) } },
        { id: '2', label: 'Add New Product', command: (event) => { this.handleInventoryAction(event) } },
        { id: '3', label: 'Download Export Template ', command: (event) => { this.handleInventoryAction(event) } },
        { id: '4', label: 'View Insructions', command: (event) => { this.handleInventoryAction(event) } },
        // { id: '5', label: 'Calander', command: (event) => { this.dateMenuOption(event) } },
    ]
    setupGude:any[] = []
    isVideosTutorialLoad: boolean = false;
    selectedTime: any;
    isShowTopProduct: boolean = false;
    setupGuideTotalSteps: number;

    constructor(
        private _bs: BaseService,
        private productService: ProductService,
        public configService: ConfigService,
        private _router: Router,
        private _spinner: NgxSpinnerService,
        public _abd: AppBaseDataService,
        private _locationServices:LocationsService,
        private _productServices:ProductsService,
        private _customerServices:CustomersService,
        private _invoiceServices:ProductsService,
        private sanitizer: DomSanitizer,
        // Lincsell---
        private _businessServices:BusinessSetupService,

    ) { }

    ngOnInit() {
        this.onResize(new Event('resize')); 
        this._abd.isUserOnDashboard = true
        this.resetDates()
        this.getSetUpGuideData()
        this.selectedTimeZone.valueChanges.subscribe((x)=>{
            this.refreshTiemTrackingList(x.value)
        })
        if(this._globals.userInfo.businessSetup[0]?.standardsForms !== undefined && this._globals.userInfo.businessSetup[0]?.standardsForms?.timeZone !== "" ){
            this.selectedTime = this.timeZoneList.filter((v)=>v.value === this._globals.userInfo.businessSetup[0]?.standardsForms.timeZone)[0]
        }else{
            this.selectedTime = this.timeZoneList[0]
        }
        this.selectedTimeZone.setValue(this.selectedTime)
        this.refreshTiemTrackingList(this.selectedTimeZone?.value?.value)
        this.getVideosTutorial()
    //  Free Trial 
        const freeTrail = this._globals.userInfo.businessSetup[0].subscription;
        this.IsUserOnTrial=this._globals.userInfo.businessSetup[0].isFreeTrial;
        if (freeTrail.isFreeTrial) {
            this.dayLeftInFreeTrial = new Date(freeTrail.freeTrialEndDate.toString());
        }
    //  =====
        this.config = this.configService.config;
        this.subscription = this.configService.configUpdate$.subscribe(config => {
            this.config = config;
            this.updateChartOptions();
        });
        this.LoadWidgetData();

        this.dailystackedOptions = {
            tooltips: {
                mode: 'index',
                intersect: false
            },
            responsive: true,
            scales: {
                x: {
                    stacked: false,
                },
                y: {
                    stacked: false
                }
            }
        };
        this.salesWidgetData = {
            // labels: ['A','B','C'],
            datasets: [
                {
                    data: [0, 0],
                    backgroundColor: [
                        "#FF6384",
                        "#36A2EB",
                    ],
                    hoverBackgroundColor: [
                        "#FF6384",
                        "#36A2EB",
                    ]
                }
            ]
        };
        this.ordersWidgetData = {
            // labels: ['A','B','C'],
            datasets: [
                {
                    data: [0, 0],
                    backgroundColor: [
                        "#FF6384",
                        "#36A2EB",
                    ],
                    hoverBackgroundColor: [
                        "#FF6384",
                        "#36A2EB",
                    ]
                }
            ]
        };

    }
    getSetUpGuideData(){
        this.activeSideBar(1) 
        this.getConfigration();
        this.getInventory()
         if(this._global.whiteLabel.wlName === 'LincSell'){
            this.getShippingConfiguration();
            this.getPaymentProcessing();
         }else{
        this.getLocations();
        this.getCustomers();
        this.getInvoice();
        this.checkDownloadPOS(); 
         }
   
    }
    getConfigration(){
        setTimeout(() => {            
            if (this._globals.userInfo.businessSetup[0].isBusinessSetup === true){ 
                this.isConfigration ={status:true, img:'../../../assets/images/tick.svg'};
            }
            else {
                    this.isConfigration ={status:false, img:'../../../assets/images/circle.svg'};  
                }
        }, 500);
    }
    ngAfterViewChecked(){
      this.checkSetupGuide()
    }
    // Lincsell---
    goToSite(){
        if(this._global.whiteLabel.wlName === 'LincSell'){
            if(this._global.webStoreConfiguration !== null){
                window.open(this._global.webStoreConfiguration?.webStoreHost,'_blank')
            }
        }
    }
    getUrl(url:any){
        if(this._global.whiteLabel.wlName === 'LincSell'){
        if(url){
            return url.replace('https://', '');
        }else{
            return "";
        }}
    }
    // Lincsell---
    // Lincsell---
    checkSetupGuide(){
        if(this._global.whiteLabel.wlName === 'LincSell'){
            // Lincsell---
              this.setupGuideTotalSteps = 4
              setTimeout(() => {
                  this.isLoadingSetup = true
                  let setpGuide:any[] = []
              setpGuide.push(this.isConfigration?.status)
              setpGuide.push(this.isInventory?.status)
              setpGuide.push(this.isShipping?.status)
              setpGuide.push(this.isPaymentProcessing?.status)
              let isShow = setpGuide.filter((v)=>v === false);
              let completeTasks = setpGuide.filter((v)=>v === true);
              this.outOf = completeTasks.length;
              let total = (this.outOf / this.setupGuideTotalSteps * 100).toString()
               let newTotal = total.split('.')
                this.proressBar = +newTotal[0]
              if(isShow.length > 0){
                  this._abd.isSetupGuide = true
                  this.isLoadingSetup = false
              }else{
                  this._abd.isSetupGuide = false
                  this.isLoadingSetup = false
              }   
              }, 500);
        // Lincsell---
          }else{
              this.setupGuideTotalSteps = 6
          setTimeout(() => {
          this.isLoadingSetup = true
          let setpGuide:any[] = []
          setpGuide.push(this.isConfigration?.status)
          setpGuide.push(this.isLocation?.status)
          setpGuide.push(this.isInventory?.status)
          setpGuide.push(this.isCustomer?.status)
          setpGuide.push(this.isPosAppDownload?.status)
          setpGuide.push(this.isInvoice?.status)
          // setpGuide.push()
          let isShow = setpGuide.filter((v)=>v === false);
          let completeTasks = setpGuide.filter((v)=>v === true);
          this.outOf = completeTasks.length;
          let total = (this.outOf / this.setupGuideTotalSteps * 100).toString()
           let newTotal = total.split('.')
            this.proressBar = +newTotal[0]
          if(isShow.length > 0){
              this._abd.isSetupGuide = true
              this.isLoadingSetup = false
          }else{
              this._abd.isSetupGuide = false
              this.isLoadingSetup = false
          }   
          }, 500);  
          }
    }
    getVideosTutorial(){
        this.isVideosTutorialLoad = true;
        let  url = environment.BusinessBaseURL + 'VideosTutorial'
        this._bs.httpGet(url).subscribe(res => {
            if (res.success) {
                let videosTutorial = res.data
                this.videosTutorial = videosTutorial.map((v)=>{
                    return{
                        ...v,videoURL:this.sanitizer.bypassSecurityTrustResourceUrl(v.url)
                    }
                    
                })
                this.isVideosTutorialLoad = false
            }
        },(error)=>{
            this.isVideosTutorialLoad = false
        })

        
    }
    checkDownloadPOS(){

        if(this._abd.registers.length > 0){
                        this.isPosAppDownload =  {status:true, img:'../../../assets/images/tick.svg'};
                    }else{
                        this.isPosAppDownload = { status:false, img: '../../../assets/images/circle.svg'};

        }
    }

    refreshTiemTrackingList(obj?:any){
        if(obj === undefined){
            obj = this.selectedTimeZone.value.value
        }

            this.isTimeTrackingRefresh = true
            let url = environment.InventoryBaseURL + `Dashboard/GetEmployeeTimeStatus?TimeZone=${obj}`
            try {
                    this._bs.httpGet(url).subscribe(res => {
                if(res.success){
                    this.timeTrackingReport = res.data
                    //   this.timeTrackingReport = this.timeTrackingReport.map((v)=>{
                    //     return{
                    //         ...v,
                    //         overtime: parseFloat(v.overtime.toFixed(2)),
                    //         undertime: parseFloat(v.undertime.toFixed(2)),
                    //     }
                    //   })
                    this.isTimeTrackingRefresh = false
                    }
                    },
                (error)=>{
                    this.isTimeTrackingRefresh = false
                }
                )
            } catch (error) {
                this.isTimeTrackingRefresh = false
            }   
        // }
        

    }
    getLocations(){
        let obj ={
            "PageNo": 0,
            "PageSize": 1,
       }
        this._locationServices.getAllLocations(obj).subscribe((res)=>{
             if(res.success){
                if(res.data.length > 0){
                    this.isLocation =  {status:true, img:'../../../assets/images/tick.svg'};
                }else{
                    this.isLocation = { status:false, img: '../../../assets/images/circle.svg'};
                }
             }
        },(error)=>{
            // console.log(error)  
             this.isLocation = { status:false, img: '../../../assets/images/circle.svg'};
            }
        )
    }
    
    getInventory(){
        let obj ={
            "PageNo": 0,
            "PageSize": 1,
       }
        this._productServices.getAllProductList4Inventory(obj).subscribe((res)=>{
             if(res.success){
                if(res.data.length >0){
                    this.isInventory =  {status:true, img:'../../../assets/images/tick.svg'};
                }else{
                    this.isInventory = { status:false, img: '../../../assets/images/circle.svg'};
                }
             }
        },(error)=>{
            // console.log(error)
            this.isInventory =  { status:false, img: '../../../assets/images/circle.svg'};
        }
        )
    }
    getCustomers(){
        let obj ={
            "PageNo": 0,
            "PageSize": 1,
            "type":"all"
       }
        this._customerServices.getAllCustomers(obj).subscribe((res)=>{
             if(res.success){
                if(res.data.length >0){
                    this.isCustomer =  {status:true, img:'../../../assets/images/tick.svg'};
                }else{
                    this.isCustomer =  { status:false, img: '../../../assets/images/circle.svg'};
                }
             }
        },(error)=>{
            // console.log(error)
            this.isCustomer =  { status:false, img: '../../../assets/images/circle.svg'};
        }
        )
    }
    getInvoice(){
        let obj ={
            PageNo: 0,
            PageSize: 1,
            orderPlacement:'All',
            invoiceType:-1,
            locationIds:[],
            startDate: formatDate("01/01/1800" , 'MM/dd/yyyy', 'en-US'),
            endDate: formatDate(new Date(), 'MM/dd/yyyy', 'en-US'),
       }
       let url = environment.OrderBaseURL + environment.FilterInvoiceHistory
       this._bs.httpPost(url, obj).subscribe(res => {
        // this._locationServices.getAllLocations(obj).subscribe((res)=>{
             if(res.success){
                if(res.data.length >0){
                    this.isInvoice =  {status:true, img:'../../../assets/images/tick.svg'};
                }else{
                    this.isInvoice =  { status:false, img: '../../../assets/images/circle.svg'};
                }
             }
        },(error)=>{
            // console.log(error) 
            this.isInvoice =  { status:false, img: '../../../assets/images/circle.svg'};
        }
        )
    }
    getShippingConfiguration(){
     // Lincsell---
        this._businessServices.getShippingConfiguration().subscribe((res)=>{
            if(res.success){
                if(res.data.isSaved === true){
                    this.isShipping =  {status:true, img:'../../../assets/images/tick.svg'};
                }else{
                    this.isShipping = { status:false, img: '../../../assets/images/circle.svg'};
                }
             }
        },(error)=>{
            // console.log(error)  
             this.isShipping = { status:false, img: '../../../assets/images/circle.svg'};
            }
        )
    }
    getPaymentProcessing(){
// Lincsell---
        this._businessServices.getPaymentProcessing().subscribe((res)=>{
             if(res.success){
                if(res.data.isSaved === true){
                    this.isPaymentProcessing =  {status:true, img:'../../../assets/images/tick.svg'};
                }else{
                    this.isPaymentProcessing = { status:false, img: '../../../assets/images/circle.svg'};
                }
             }
        },(error)=>{
            // console.log(error)  
             this.isPaymentProcessing = { status:false, img: '../../../assets/images/circle.svg'};
            }
        )
    }
    openConfigration(value){
    //  if(value === false){
        this._router.navigate(['setup/business-setup'])
    //  }
    }
    openLocation(value){
  if(value === false){
    this._router.navigate(['app/locations-list'])
     }
    }
    openInventory(value){
        if(value === false){
            this._router.navigate(['app/products'])
        }
    }
    openCustomer(value){
        if(value === false){
            this._router.navigate(['app/customers'])
        }
    }
    openShippingConfig(value){
            this._router.navigate(['app/business'], { 
                queryParams: { page: 'shipping' }
              });
        
    }
    openPaymentProcessing(value){
        this._router.navigate(['app/business'], { 
                queryParams: { page: 'payment-processing' }
              });
        
    }
    openInvoice(value){
        if(value === false){
            // this._router.navigate(['app/sales-invoices'])
        }
    }
    openPosAppDownload(value){
            this._abd.isDownloadPosApp = true;
            this.isPosAppDownload = true;
            sessionStorage.setItem('pos-app-download', '1')
            this.checkDownloadPOS()
    }
    activeSideBar(value:any) {
          this.slideOffset ={
            offset:-0,
            page:value
          };
          this.activeOrder = value
      }

        toggleTopPanel() {
          this.isTopPanelOpen = !this.isTopPanelOpen;
          this.expandPanelState = this.expandPanelState === 'expanded' ? 'collapsed' : 'expanded';
    
        }
        togglePanel() {
          this.isPanelOpen = !this.isPanelOpen;
          this.expandState = this.expandState === 'expanded' ? 'collapsed' : 'expanded';
    
        }
    updateChartOptions() {
        if (this.config.dark)
            this.applyDarkTheme();
        else
            this.applyLightTheme();

    }
    dateMenuOption(e: any) {
        const today = new Date()
        let sd: any = ''
        let ed: any = ''
        this.selectedPeriod = e.item.label;
        if (this.selectedPeriod === "Today") {
            this.dashboardFilter = "0"
            this.dateRange = []
            sd = new Date()
            ed = new Date()
            this.dateRange.push(sd)
            this.dateRange.push(ed)
        }
        else if (this.selectedPeriod === "Current Month") {
            this.dashboardFilter = "1"
            this.dateRange = []
            sd = new Date(today.getFullYear(), today.getMonth(), 1)
            ed = new Date()
            this.dateRange.push(sd)
            this.dateRange.push(ed)
        }
        else if (this.selectedPeriod === "Current Year") {
            this.dashboardFilter = "2"
            this.dateRange = []
            sd = new Date(today.getFullYear(), 0, 1)
            ed = new Date()
            this.dateRange.push(sd)
            this.dateRange.push(ed)
        }
        else if (this.selectedPeriod === "Calander")
            this.dashboardFilter = "2"

        this.LoadWidgetData();
    }
    handleInventoryAction(event: any) {
        if (event.item.id == 1)
            this.goToImportPage();
        else if (event.item.id == 2)
            this._router.navigate(['app/new-product'])
        else if (event.item.id == 3)
            window.open("https://cloudstoragesvc.blob.core.windows.net/commonfiles/Production/ImportProductsCSVTemplate.csv")
        else if (event.item.id == 4)
            window.open("https://cloudstoragesvc.blob.core.windows.net/commonfiles/Production/ConnectImportInventoryInstructions.docx")

    }
    isHandheld() {
        if (window.location.href.includes('/handheld'))
            return true
        else
            return false
    }

    LoadWidgetData() {
        this._abd.showSpinner();

        this.saleWidgetmain =
        {
            SaleDifference: 0.0,
            SaleDifferencepercent: 0,
            OrderDifference: 0,
            OrderDifferencepercent: 0

        }

        let url = environment.InventoryBaseURL + environment.GetTopWidgets + '?dashboardFilter=' + this.dashboardFilter
        this._bs.httpGet(url).subscribe(res => {
            if (res.success) {
                this.dataList = res.data
                this.saleWidgetmain = res.data;

                this.saleWidgetmain.SaleDifference = res.data?.totalSales - res.data?.previousTotalSales;
                this.saleWidgetmain.SaleDifferencepercent = (this.saleWidgetmain.SaleDifference / (res.data?.previousTotalSales == 0 ? 100 : res.data?.previousTotalSales) * 100)

                // this.saleWidgetmain.SaleDifference = 100
                // this.saleWidgetmain.SaleDifferencepercent = 100

                this.saleWidgetmain.OrderDifference = res.data?.totalOrders - res.data?.previousTotalOrders;
                this.saleWidgetmain.OrderDifferencepercent = (this.saleWidgetmain.OrderDifference / (res.data?.previousTotalOrders == 0 ? 100 : res.data?.previousTotalOrders) * 100)

                this.ordersWidgetData = {
                    // labels: ['In-Store','Online'],
                    datasets: [
                        {
                            data: [res?.data.totalOfflineOrders, res?.data.totalOnlineOrders],
                            backgroundColor: [
                                "#FF6384",
                                "#36A2EB",
                            ],
                            hoverBackgroundColor: [
                                "#FF6384",
                                "#36A2EB",
                            ]
                        }
                    ]
                };
                this.salesWidgetData = {
                    // labels: ['In-Store','Online'],
                    datasets: [
                        {
                            data: [res?.data.totalOfflineSales, res?.data.totalOnlineSales],
                            backgroundColor: [
                                "#FF6384",
                                "#36A2EB",
                            ],
                            hoverBackgroundColor: [
                                "#FF6384",
                                "#36A2EB",
                            ]
                        }
                    ]
                };
            }
        })

        url = environment.InventoryBaseURL + environment.GetTopSoldProducts + '?dashboardFilter=' + this.dashboardFilter
        this._bs.httpGet(url).subscribe(res => {
            if (res.success) {
                this.topSellingProducts = res.data
                //console.log('topSellingProducts', this.topSellingProducts)
            }
        })

        url = environment.InventoryBaseURL + environment.GetLowStockProducts + '?dashboardFilter=' + this.dashboardFilter
        this._bs.httpGet(url).subscribe(res => {
            if (res.success) {
                this.lowStockProducts = res.data
                //console.log('lowStockProducts', this.lowStockProducts)
            }
        })

        this.getDailyAverageSales()

        this._abd.hideSpinner();
    }
    applyDarkTheme() {
        this.chartOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: '#ebedef'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#ebedef'
                    },
                    grid: {
                        color: 'rgba(160, 167, 181, .3)',
                    }
                },
                y: {
                    ticks: {
                        color: '#ebedef'
                    },
                    grid: {
                        color: 'rgba(160, 167, 181, .3)',
                    }
                },
            }
        };
    }

    applyLightTheme() {
        this.chartOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef',
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef',
                    }
                },
            }
        };
    }
    goToImportPage() {
        this._router.navigate(['app/importproducts'])
    }

    resetDates() {
        this.dateRange = []
        this.dateRange[0] = new Date()
        this.dateRange[1] = new Date()

    }

    getDailyAverageSales() {

        try {
            if (this.dateRange.length < 2) this.resetDates()
        } catch { this.resetDates() }

        const data = {
            "ReportName": 'SaleReport',
            "Parameter": {
                "StartDate": formatDate(this.dateRange[0], 'MM/dd/yyyy', 'en-US'),
                "EndDate": formatDate(this.dateRange[1], 'MM/dd/yyyy', 'en-US'),
            }
        }

        const url = this._abd.reports.filter(x => x.componentName == 'Daily Sales Report')[0].reportURL;
        this._abd.showSpinner();
        this._bs.httpPost(url, JSON.stringify(data)).subscribe(res => {
            if (res.success && res.data?.length > 0) {
                let dailySaleDataarray = []
                let dailyInvoceCount = []
                let creditCardTotalArray = []
                let CashTotalArray = []
                let totalSale = 0
                let label = []
                let dddata = res.data?.sort((a, b) => (a.date < b.date ? -1 : 1));
                for (let i = 0; i < res.data?.length; i++) {
                    let obj = dddata[i]
                    totalSale += (obj.subTotal - obj.discount)
                    dailySaleDataarray.push(obj.subTotal - obj.discount)
                    dailyInvoceCount.push(obj.totalInvoices);
                    creditCardTotalArray.push(obj.creditCardAmount);
                    CashTotalArray.push(obj.cashAmount);
                    label.push(formatDate(obj.date, 'd MMM', 'en-US'))

                }
                this.dailyAvgSale = totalSale / (res.data?.length > 0 ? res.data?.length : 1)

                this.dailysales = {
                    // labels: ['Monday', 'Tuseday', 'Wedenesday', 'Thursday', 'Friday', 'Satarday', 'Sunday'],
                    labels: label,
                    datasets: [
                        {
                            type: 'bar',
                            label: 'Net Sales ($)',
                            backgroundColor: '#0095e8',
                            data: dailySaleDataarray
                        },
                        {
                            type: 'bar',
                            label: 'Credit Card ($)',
                            backgroundColor: '#fd7e14',
                            data: creditCardTotalArray
                        },
                        {
                            type: 'bar',
                            label: 'Cash ($)',
                            backgroundColor: '#fbc02d',
                            data: CashTotalArray
                        },

                    ]
                };
            }
        })
    }

    onRowSelectLowStockProduct(e) {
        this.gProductIdLowStockProduct = e.data.itemSKUId
        this.lowStockProductSelectedRow = JSON.parse(JSON.stringify(e.data))
    }

    onRowUnselectLowStockProduct(e) { }
    navigateToSubscription(){
        this._router.navigateByUrl('app/subscription-management')
    }
    ngOnDestroy(): void {
        this._abd.isUserOnDashboard = false;
    }
    // UI Responsive
    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.isShowTopProduct = window.innerWidth > 991;
    }
}

